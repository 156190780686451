// plf user permissions
export const ATTORNEYS = 'attorneys';
export const FIRM_USERS = 'firm_users';
export const CAN_EDIT_PLF_INFO = 'can_edit_plf_info';
export const CAN_EDIT_PAYMENT_INFO = 'can_edit_payment_info';
export const CAN_MANAGE_PLF_USERS = 'can_manage_plf_users';
export const CAN_EDIT_SERVICES = 'can_edit_cases';
export const CAN_SUBMIT_CLAIMS = 'can_submit_claims';
export const CAN_DO_INTAKE = 'can_do_intake';
export const PRIMARY_ATTORNEYS = 'primary_attorneys';
// Groups assigned to primary attorney on firm intake and registration
export const ALL_ATTORNEY_PERMISSIONS = [
  ATTORNEYS,
  PRIMARY_ATTORNEYS,
  CAN_EDIT_PLF_INFO,
  CAN_MANAGE_PLF_USERS,
  CAN_EDIT_SERVICES,
  CAN_SUBMIT_CLAIMS,
  CAN_DO_INTAKE,
  CAN_EDIT_PAYMENT_INFO,
];
// Group assigned to additional attorneys specified on firm registration
export const NOT_CONTACT_ATTORNEY_PERMISSIONS = [
  ATTORNEYS,
  CAN_EDIT_PLF_INFO,
  CAN_MANAGE_PLF_USERS,
  CAN_EDIT_SERVICES,
  CAN_SUBMIT_CLAIMS,
  CAN_DO_INTAKE,
  CAN_EDIT_PAYMENT_INFO,
];
// panel user permissions
export const PANEL_USERS = 'panel_users';
export const CAN_MANAGE_PANEL_USERS = 'can_manage_panel_users';
export const CAN_BROADCAST_MEMOS = 'can_broadcast_memos';
export const CAN_REGISTER_FIRM = 'can_register_firm';
export const CAN_APPROVE_MALPRACTICE = 'can_approve_malpractice';
export const CAN_APPROVE_OFFICE_CHANGE = 'can_approve_office_change';
export const CAN_APPROVE_EXTRA_FEE = 'can_approve_extra_fee';
export const CAN_EDIT_LOGIN_EMAIL = 'can_edit_login_email';
export const CAN_MANAGE_DOCUMENTS = 'can_manage_documents';
export const CAN_EDIT_OFFICE_ATTORNEYS_AND_SERVICES = 'can_edit_office_attorneys_and_services';

// claims user permissions
export const CAN_APPROVE_KICKS = 'can_approve_kicks';
export const CAN_EDIT_KICKS = 'can_edit_kicks';
export const CAN_EDIT_FEE_SCHEDULE = 'can_edit_fee_schedule';
export const CAN_EDIT_SERVICE_GROUP_PARAMETERS = 'can_edit_service_group_parameters';
export const CAN_SUBMIT_FEE_REIMBURSEMENTS = 'can_submit_fee_reimbursements';
export const CAN_SUBMIT_CLAIM_FOR_FIRM = 'can_submit_claim_for_firm';
export const CAN_SEND_PAYMENT_EMAILS = 'can_send_payment_emails';
export const CAN_APPROVE_FEE_REIMBURSEMENT_PAYMENTS = 'can_approve_fee_reimbursement_payments';
export const CLAIMS_USERS = 'claims_users';

export const INTERNAL_USERS = [CLAIMS_USERS, PANEL_USERS];

export const PANEL_REP = 'Panel Rep';
export const CLAIMS_REVIEWER = 'Claims Reviewer';
export const ADMIN = 'Administrator';
