import client from 'api/client';

import createURLParams from 'utils/urls';

export const getFirmUpdateNotifications = ({ limit = 0, offset = 0, sortBy = '', params = {} }) => {
  const queryParams = createURLParams({ limit, offset, ordering: sortBy, ...params });
  return client.get(`firm-updates/`, { params: queryParams });
};

export const rejectFirmUpdate = async id => {
  const { data } = await client.post(`firm-updates/${id}/deny-update/`);
  return data;
};

export const approveFirmUpdate = async id => {
  const { data } = await client.post(`firm-updates/${id}/approve-update/`);
  return data;
};

export const getFirmUpdateNotes = async id => {
  const queryParams = createURLParams({ firm_update_notification__id: id });
  const { data } = await client.get(`firm-updates/notes/`, { params: queryParams });
  return data;
};

export const addNote = async payload => {
  const { data } = await client.post(`firm-updates/notes/`, payload);
  return data;
};
