import client, { CLIENT_GET_FILE, CLIENT_POST_FILE } from 'api/client';

import createURLParams from 'utils/urls';

export const getFeeScheduleDetails = payload => {
  const params = createURLParams(payload);
  return client.get('fee_schedule/', { params });
};

export const getFeeScheduleDocument = name => {
  const params = createURLParams({ filename: name });
  return CLIENT_GET_FILE.get('fee_schedule/get-fee-schedule-document/', { params });
};

export const updateFeeScheduleDocument = payload => {
  return CLIENT_POST_FILE.post('fee_schedule/update-fee-schedule-document/', payload);
};

export const findAndUpdateFeeSchedule = (id, payload) =>
  client.patch(`fee_schedule/${id}/`, payload);

export const findAndUpdateStandardFee = (id, payload) =>
  client.patch(`fee_schedule/standards/${id}/`, payload);

export const createStandardFee = payload => client.post('fee_schedule/standards/', payload);

export const getNegotiatedFeesForFirm = id => {
  const queryParams = createURLParams({ firmId: id });
  return client.get('fee_schedule/negotiated-fees/', { params: queryParams });
};

export const createNegotiatedFee = payload => client.post('fee_schedule/negotiated-fees/', payload);

export const findAndUpdateNegotiatedFee = (id, payload) =>
  client.patch(`fee_schedule/negotiated-fees/${id}/`, payload);

export const deleteNegotiatedFee = async id => {
  const { data } = client.delete(`fee_schedule/negotiated-fees/${id}/`);
  return data;
};

export const getNegotiatedFeeDocument = id =>
  CLIENT_GET_FILE.get(`fee_schedule/negotiated-fee-documents/${id}`);
