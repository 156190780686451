import client from 'api/client';

export const addOffice = async payload => {
  const { data } = await client.post(`firms/offices/`, payload);
  return data;
};

export const findAndUpdateOffice = async payload => {
  const { data } = await client.patch(`firms/offices/${payload.id}/`, payload);
  return data;
};

export const deleteOffice = id => client.delete(`firms/offices/${id}/`);

export const getPendingOffices = () => client.get('firms/offices/?fsm_state=SUBMITTED');

export const approveOffice = id => client.patch(`firms/offices/approve-office/`, { id });

export const denyOffice = id => client.patch(`firms/offices/deny-office/`, { id });

export const validateOfficeData = payload => client.post('firms/offices/validate-office/', payload);

export const updateOfficeAttorneys = async payload => {
  const { data } = await client.patch(`firms/offices/${payload.id}/update-attorneys/`, payload);
  return data;
};

export const changePrimaryOffice = async payload => {
  const { data } = await client.patch(`firms/${payload.firm}/change-primary-office/`, {
    new_primary_office_id: payload.id,
  });
  return data;
};
