import { observer } from 'mobx-react';

import PropTypes from 'prop-types';

import { makeStyles } from '@bequestinc/wui';
import Typography from '@mui/material/Typography';

import Page from 'components/Page';

const useStyles = makeStyles()({
  text: {
    padding: 40,
  },
});

const NoAccess = ({ forAttorneyUnderReview }) => {
  const { classes } = useStyles();

  return (
    <Page title="Access Denied">
      {forAttorneyUnderReview ? (
        <Typography variant="h2" align="center" className={classes.text}>
          Your attorney profile is under review by MLP
        </Typography>
      ) : (
        <>
          <Typography variant="h1" align="center" className={classes.text}>
            Access Denied
          </Typography>

          <Typography variant="h2" align="center" className={classes.text}>
            You do not have access to view this page!
          </Typography>
        </>
      )}
    </Page>
  );
};

NoAccess.propTypes = {
  forAttorneyUnderReview: PropTypes.bool,
};

NoAccess.defaultProps = {
  forAttorneyUnderReview: false,
};

export default observer(NoAccess);
